import { Component } from '@angular/core';
import { SearchService } from '../../services/search.service';
import { SpinnerService } from 'src/app/main/services/spinner/spinner.service';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent {
search=''
type='All'
searches:string[]=[]

constructor(private product:SearchService,private spin:SpinnerService,private router:Router){}
store:string=''
route='/home'
async ngOnInit(){
  this.getRecentSearches()
  this.store = await this.product.getSuitableStore() ||''

  this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: any) => {
        this.route =  event.urlAfterRedirects
 });
  }
 


closeSearch(){
this.spin.hideSearch()
}

getRecentSearches(){
this.searches = JSON.parse(localStorage.getItem('recentSearches') || '[]')
}

async submit(){
  this.userDetails = await this.product.getUserdetails()

  if(this.search.length<=2)
  return alert('Please Enter More precised name')
  this.groceries=[]
  this.santhe =[]
  this.foods = []
  this.stores = []



  this.searches = [...[this.search],...this.searches]
  this.searches =  this.searches.slice(0,this.searches.length>10?10:this.searches.length)
  localStorage.setItem('recentSearches',JSON.stringify(this.searches))



  if(this.type=='All'){
    this.searchFoodProductsStore()
    if(this.store!='')
    this.searchGroceryProducts()
    this.searchSantheProducts()
    this.searchNearbyStores()
  }else if(this.type=='Grocery'){
    if(this.store!='')
    this.searchGroceryProducts()
  }else if(this.type=='Cafe'){
    this.searchFoodProductsStore()
  }else if(this.type=='Other'){
    this.searchSantheProducts()
  }
}

foods:any[]=[]
fload=false
userDetails:any
async searchFoodProductsStore(){
  this.fload=true
  let stores;
  if(sessionStorage.getItem('nearestAllStores'))
  stores = JSON.parse(sessionStorage.getItem('nearestAllStores') || '[]')
  else
  stores = await this.product.getNearestStores(this.userDetails?.location?.cord) || []
  
  if(stores.length!=0)
  Object.values(stores || {}).forEach(async id=>{
    let services = await this.product.getDataByPath('/store/'+id+'/services') || []
    let status = await this.product.getDataByPath('/store/'+id+'/status')
    let products=Object.create({})


    if(status){
    if(this.search!='') {
      let cproducts = await  this.product.getDataByPath('/search/products/Cafe/'+id+'/id/')
      for(let i  in cproducts){
        if(!products[i] && (cproducts[i].n?.toLowerCase()?.includes(this.search.toLowerCase()) || cproducts[i]?.c?.toLowerCase().includes(this.search.toLowerCase()) || cproducts[i].t?.toLowerCase().split(',')?.includes(this.search.toLowerCase())) )
        products[i] = {name:cproducts[i].n,imgLink:await this.product.getDataByPath('/store/'+id+'/cafeMenu/'+i+'/imgLink/0')}
      }
    }
    products=(Object.values(products || {}))
    let name  = await this.product.getDataByPath('/store/'+id+'/name')
    if(services?.includes('Cafe') && (this.search=='' ||  (this.search!='' && Object.values(products).length!=0 || name?.toLowerCase()?.includes(this.search.toLowerCase())))){
    let cords2 = await this.product.getDataByPath('store/'+id+'/location/cord')
    
    for(let a of products){
      // console.log(a)
    this.foods.push( { 
      id,name:a.name,
      imgLink: a.imgLink,
      services,
      tagline:name,
      distance: this.product.getDistanceValue(this.userDetails?.location?.cord,cords2),
    })}
    }}
    this.fload = false
  })
  else
  this.fload=false
}

groceries:any[]=[]
gload=false
async searchGroceryProducts(){
  this.gload=true

  let stores;
  if(sessionStorage.getItem('nearestAllStores'))
  stores = JSON.parse(sessionStorage.getItem('nearestAllStores') || '[]')
  else
  stores = await this.product.getNearestStores(this.userDetails?.location?.cord) || []
  
 if(this.store.length!=0) 
  Object.values(stores || {}).forEach(async id=>{
    let services = await this.product.getDataByPath('/store/'+id+'/services') || []

    let status = await this.product.getDataByPath('/store/'+id+'/status')

    let products=Object.create({})
    if(status){
    if(this.search!='') {
      let cproducts = await  this.product.getDataByPath('/search/products/Grocery/'+id+'/id/')
      for(let i  in cproducts){
        if(!products[i] && (cproducts[i].n?.toLowerCase()?.includes(this.search.toLowerCase()) || cproducts[i]?.c?.toLowerCase()?.includes(this.search.toLowerCase()) || cproducts[i].t?.toLowerCase()?.split(',')?.includes(this.search.toLowerCase())) )
        products[i] = {id:i,price:cproducts[i].p,name:cproducts[i].n,imgLink:await this.product.getDataByPath('/store/'+id+'/products/'+i+'/imgLink/0')}
      }
    }
    products=(Object.values(products || {}))
    let name  = await this.product.getDataByPath('/store/'+id+'/name')
    if(services?.includes('Grocery') && (this.search=='' ||  (this.search!='' && Object.values(products).length!=0 || name?.toLowerCase()?.includes(this.search.toLowerCase())))){    
    for(let a of products){
    this.groceries.push({ 
      id:a.id,
      store:id,name:a.name,
      imgLink: a.imgLink,
      tagline:name,type:'Quick',varId:'0',
      price: a.price ||  await this.product.getDataByPath('/store/'+id+'/products/'+a.id+'/Variance/0/discPrice'),
    })}}}
    this.gload=false
  })
  else
  this.gload=false
}
santhe:any[]=[]
sload=false

raw:any
async searchSantheProducts(){
  this.sload=true
  if(!this.raw)
  this.raw  = await this.product.getDataByPath('/search/products/Santhe/category/')
 
  // console.log(this.raw)
  if(this.raw)
  Object.entries(this.raw || {}).forEach(async cat=>{
    Object.entries(cat[1] || {}).forEach(async (prod:any)=>{
      if(prod?.[1]?.n?.toLowerCase()?.includes(this.search.toLowerCase()) || prod?.[0]==this.search ||  cat[0]?.toLowerCase()?.includes(this.search.toLowerCase())){     
        if(this.santhe.length<20)
        this.santhe.push({
          id:prod[0],
          varId:'0',imgLink:await this.product.getDataByPath('santheProducts/'+prod[0]+'/imgLink/0'),
          type:'Santhe',
          name:prod[1].n,
          price:prod[1].p,
          store:await this.product.getDataByPath('santheProducts/'+prod[0]+'/store')
        })
      }
    })
    this.sload=false
  })
  else


  this.sload=false
}

stores:any[]=[]
async searchNearbyStores(){

}

marketplace:any[]=[]
async searchMarketplace(){
  
}

}
