import { Injectable } from '@angular/core';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {firebaseConfig} from '../../../environments/environment'
import {getDatabase} from 'firebase/database'
import { getStorage } from 'firebase/storage'
import {getAuth} from 'firebase/auth'

const app = initializeApp(firebaseConfig);
const db =  getDatabase(app);
const anal = getAnalytics(app);
const storage = getStorage(app);
const auth =  getAuth(app);

@Injectable({
  providedIn: 'root'
})
export class FirebaseService {

  constructor() { }

  getApp(){
    return app
  }

  getAuth(){
    return auth
  }

  isAuthenticated():Promise<boolean>{
    return new Promise((resolve, reject) => {
      auth.onAuthStateChanged(user => {
        if (user) {
          resolve(true); // User is authenticated
        } else {
          resolve(false); // User is not authenticated
        }
      });
    });
  }

  getStorage(){
    return storage
  }

  getDatabase(){
    return db
  }

  getAnaytics(){
    return anal
  }

}
