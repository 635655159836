import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {

 
  showSpinnerSubject : Subject<boolean> = new Subject<boolean>()
  public showSpinner$: Observable<boolean> = this.showSpinnerSubject.asObservable();


  showSearch : Subject<boolean> = new Subject<boolean>()
  public showSearch$: Observable<boolean> = this.showSearch.asObservable();


  showSpinner(): void {
    this.showSpinnerSubject.next(true);
  }

  hideSpinner(): void {
    this.showSpinnerSubject.next(false);
  }

  showSearchcomp(): void {
    this.showSearch.next(true);
  }

  hideSearch(): void {
    this.showSearch.next(false);
  }
 
}
