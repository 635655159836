
<div class="px-2 position-relative"  style="overflow-y: scroll;max-width: 1000px;" >

<form (submit)="submit()" class="d-flex align-items-center border w-100 rounded-pill" style="height: fit-content;margin-top: 10px;padding-top: 6px;">
    
<select name="type" [(ngModel)]="type" class="border-0 ms-2  rounded-0 fs-8 text-secondary "> 
    <option value="All">All</option>
    <option  value="Grocery" >Grocery</option>
    <option  value="Cafe">Food</option>
    <option  value="Store">Other</option>
</select>
<input name="search" [(ngModel)]="search" style="outline: none;"  class="border-0 p-1 ps-2  rounded-pill  w-100 fs-8" placeholder="Search Products">
    
    <button type="submit" class="border-0" style="background-color: rgba(240, 248, 255, 0);"><i class="fi fi-rs-search text-secondary px-2 fs-5"></i></button>
</form>



<p  *ngIf="searches.length!=0"class="m-0 p-0 mt-2 fs-9">Recent Searches</p>
<div class="d-flex flex-wrap">
    <span (click)="search=a;submit()" *ngFor="let a of searches" class="bg-secondary-subtle m-1 px-2 text-secondary fw-bold fs-9">{{a}}</span>
</div>

<div *ngIf="gload"  class="border rounded d-flex  p-1 ">
    <img class="bg-secondary-subtle placeholder-wave" style="width: 80px;height: 80px;">
    <div class="ps-2 w-100">
        <h6 class="p-0 m-0 mt-1 fs-8 fw-bolder border-bottom text-center w-50 bg-secondary-subtle placeholder-wave " style="height: 15px;"></h6>
        <h6 class="p-0 m-0 mt-1  fs-8 fw-bolder border-bottom text-center w-100 bg-secondary-subtle placeholder-wave " style="height: 15px;"></h6>
        <h6 class="p-0 m-0 mt-1  fs-8 fw-bolder border-bottom text-center w-100 bg-secondary-subtle placeholder-wave " style="height: 15px;"></h6>
    </div>
</div>

<div *ngIf="fload"  class="border rounded d-flex  p-1 ">
    <img class="bg-secondary-subtle placeholder-wave" style="width: 80px;height: 80px;">
    <div class="ps-2 w-100">
        <h6 class="p-0 m-0 mt-1 fs-8 fw-bolder border-bottom text-center w-50 bg-secondary-subtle placeholder-wave " style="height: 15px;"></h6>
        <h6 class="p-0 m-0 mt-1  fs-8 fw-bolder border-bottom text-center w-100 bg-secondary-subtle placeholder-wave " style="height: 15px;"></h6>
        <h6 class="p-0 m-0 mt-1  fs-8 fw-bolder border-bottom text-center w-100 bg-secondary-subtle placeholder-wave " style="height: 15px;"></h6>
    </div>
</div>

<div *ngIf="sload"  class="border rounded d-flex  p-1 ">
    <img class="bg-secondary-subtle placeholder-wave" style="width: 80px;height: 80px;">
    <div class="ps-2 w-100">
        <h6 class="p-0 m-0 mt-1 fs-8 fw-bolder border-bottom text-center w-50 bg-secondary-subtle placeholder-wave " style="height: 15px;"></h6>
        <h6 class="p-0 m-0 mt-1  fs-8 fw-bolder border-bottom text-center w-100 bg-secondary-subtle placeholder-wave " style="height: 15px;"></h6>
        <h6 class="p-0 m-0 mt-1  fs-8 fw-bolder border-bottom text-center w-100 bg-secondary-subtle placeholder-wave " style="height: 15px;"></h6>
    </div>
</div>

<div *ngIf="!gload && groceries.length>0" class="mt-2">
<a class="text-decoration-none mt-1 float-start fs-8 " style="color: black;">Results From Groceries...</a>
<a routerLink="/home/p/products" [queryParams]="{type:'Grocery',q:search}" [replaceUrl]="(route!='/home' && route!='/home/foods' && route!='/home/categories' && route!='/' && route!='/home/services' && route!='/home/marketplace')" (click)="closeSearch()" class="text-decoration-none mt-1 text-end fs-8 ms-auto d-block " style="color: orangered;">View All</a>
<div class="w-100 d-flex flex-wrap"  >
    <div *ngFor="let a of groceries;let i=index;"   style="transition: 1s;padding: 2px;" class="border prodBox  position-relative">
        <img   [routerLink]="'/home/p/Proddetails/'+a.type+'/'+a?.id+'/'+a?.varId+'/'+a.store" (click)="closeSearch()"  style="aspect-ratio: 1;"  class="mx-auto w-100 d-block bg-secondary-subtle" [src]="a.imgLink"  >
        <p class="p-0 m-0 fs-7  w-100 text-primary-blue fw-bold text-truncate">{{a.name}}</p>
        <p class="p-0 m-0 fs-7 w-100 text-secondary text-truncate">{{a.tagline}} </p>
        <p class="p-0 m-0 fs-9 w-100 text-dark text-truncate">From Rs {{a.price }} </p>
    </div>
</div>
</div>

<div *ngIf="!sload && santhe.length>0" class="mt-2">
    <a class="text-decoration-none mt-1 float-start fs-8 " style="color: black;">Results From Bazaar..</a>
    <a routerLink="/home/p/products" [queryParams]="{type:'Santhe',q:search}" [replaceUrl]="(route!='/home' && route!='/home/foods' && route!='/home/categories' && route!='/' && route!='/home/services' && route!='/home/marketplace')" (click)="closeSearch()" class="text-decoration-none mt-1 text-end fs-8 ms-auto d-block " style="color: orangered;">View All</a>
    <div class="w-100 d-flex flex-wrap">
        <div *ngFor="let a of santhe;let i=index;"   style="transition: 1s;padding: 2px;" class="border   position-relative prodBox">
            <img  [routerLink]="'/home/p/Proddetails/'+a.type+'/'+a?.id+'/'+a?.varId+'/'+a.store" (click)="closeSearch()"  style="aspect-ratio: 1;"  class="mx-auto w-100 d-block bg-secondary-subtle" [src]="a.imgLink"  >
            <p class="p-0 m-0 fs-7  w-100 text-primary-blue fw-bold text-truncate">{{a.name}}</p>
            <p class="p-0 m-0 fs-9 w-100 text-dark text-truncate">From Rs {{a?.price }} </p>
        </div>
</div>
</div>

<div *ngIf="!fload && foods.length>0">
    <a class="text-decoration-none mt-1 float-start fs-8 " style="color: black;">Relevant Restaurants..</a>
    <a routerLink="/home/foods/all" [queryParams]="{q:search}" (click)="closeSearch()" class="text-decoration-none mt-1 text-end fs-8 ms-auto d-block " style="color: orangered;">View All</a>
    <div  class="w-100 d-flex flex-wrap">
        <div class="prodBox" *ngFor="let b of (foods || [])" [routerLink]="'/home/foods/all/'+b.id" [replaceUrl]="(route!='/home' && route!='/home/foods' && route!='/home/categories' && route!='/' && route!='/home/services' && route!='/home/marketplace')" (click)="closeSearch()"  >
            <div [style]="'background-image: url('+(b.imgLink||'https://cdn-icons-png.freepik.com/512/169/169884.png')+')'" class="w-100 rounded" style="aspect-ratio: 1; background-position: center;background-size: cover;" >
                <div class="position-relative w-100 h-100 p-2 d-flex align-items-end"  style="background:linear-gradient(to bottom,rgba(255, 255, 255, 0),black);">
                    <p *ngIf="b.distance || b.distance==0" class="p-0 m-0 text-white ms-auto d-block  fs-9">{{(b.distance | number:'2.0-1')+' KM' }}</p>
                </div>
             
            </div>
            <p class="p-0  m-0  text-truncate fw-bold fs-8 text-dark">{{b?.name}}</p>
            <p class="p-0  m-0  text-truncate text-secondary fs-9 text-dark">{{b?.tagline}}</p>

            </div>
</div>
</div>

<br>
<br><br><br>

</div>

<div class="position-absolute  fixed-bottom fixed-left w-100" style="bottom: 50px;">
    <div (click)="closeSearch()" style="height: 35px;width: 35px;" class="mx-auto justify-content-center d-flex align-items-center  shadow rounded-circle border">
        <p class="p-0 m-0 text-center   ">X</p>
    </div>
</div>