import { Injectable } from '@angular/core';
import {  CanActivate, Router} from '@angular/router';
import { FirebaseService } from 'src/app/main/services/firebase/firebase.service';
import { UserdataservieService } from 'src/app/main/services/firebase/userdata.service';

@Injectable({
  providedIn: 'root'
})
export class LoginguardGuard implements CanActivate {
 
  constructor(private fire:FirebaseService,private router:Router,private userdataserv:UserdataservieService){}

  async canActivate(){
     
   return this.fire.isAuthenticated().then(async (authenticated)=>{
      if(authenticated){
       let admins = await  this.userdataserv.getDataByPath('admin/access')
       let access =false 
      //  console.log(admins)
       Object.values(admins|| {}).forEach(el=>{ 
          if(this.fire.getAuth().currentUser?.uid == el){
            access=true
          }
        })
        return access
      }
      else{
        return false
      }
    })
      
  }
  
}
