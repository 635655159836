import { Injectable } from '@angular/core';
import { FirebaseService } from '../../services/firebase/firebase.service';
import { get, ref } from 'firebase/database';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  constructor(private fire:FirebaseService,private router:Router) {}


  async   getUserdetails(){
    let user = sessionStorage.getItem('userDetails') 
    if(user){
      return JSON.parse(user)
    }
    else{
      let a  = await  this.fire.isAuthenticated()
      let uid = this.fire.getAuth().currentUser?.uid
      let userData={
        uid:uid,
       name: (await get(ref(this.fire.getDatabase(),'user/'+uid+'/name'))).val(),
       number: (await get(ref(this.fire.getDatabase(),'user/'+uid+'/number'))).val(),
       location:(await get(ref(this.fire.getDatabase(),'user/'+uid+'/location'))).val(),
     }
      if(!userData?.name){
        this.router.navigate(['/welcome']);
      } 
      // sessionStorage.setItem('userDetails',JSON.stringify(userData))  
   return userData
}
   }



async    getUserCordinates(){
  let user = JSON.parse(sessionStorage.getItem('userDetails') || '{}')
  if(user?.location?.cord)
  return user?.location?.cord
  else{
    if(!user?.uid){
      let a  = await  this.fire.isAuthenticated()
      user.uid= this.fire.getAuth().currentUser?.uid
    }
    return await this.getDataByPath('user/'+user?.uid+'/location/cord')
  }
   }

   async compareBestStoreForHome(stores:string[]=[]){
    let proximities:{store:string,proximity:number}[] =[]
    for(let id of stores){
      let proxy=0; 
      let services  =  await this.getDataByPath('store/'+id+'/services') || []
      
      if(services.includes('Grocery') || services.includes('Cafe') || services.includes('Santhe')){
      proxy = proxy + (await this.getDataByPath('store/'+id+'/rating') || 4)
      proxy = proxy + (await this.getDataByPath('store/'+id+'/premium') || 0)
      proximities.push({store:id,proximity:proxy})
      }}
    let a  =  proximities.sort((a:any,b:any)=> b.proximity-a.proximity)
    sessionStorage.setItem('suitableStore',a[0].store)
    return a[0].store
   }

async getSuitableStore(){

  if(sessionStorage.getItem('suitableStore'))
  return sessionStorage.getItem('suitableStore')
else{
  let cords = await  this.getUserCordinates()
  if(!cords)
  return null
  else{
    let stores =await this.getNearestStores(cords)
    if(stores.length!=0){
     let store  = await  this.compareBestStoreForHome(stores)
      return store
    }else
    return null
  }
}
 }


getDistanceValue(cord1:{lat:string,lon:string},cord2:{lat:string,lon:string}){

  const dLat = this.toRad(parseFloat(cord2.lat) - parseFloat(cord1.lat));
  const dLon = this.toRad(parseFloat(cord2.lon) - parseFloat(cord1.lon));
  const lat1 = this.toRad(parseFloat(cord1.lat));
  const lat2 = this.toRad(parseFloat(cord2.lat));
  const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
  Math.cos(lat1) * Math.cos(lat2) *
  Math.sin(dLon / 2) * Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return this.R * c;
 }
 R = 6371

toRad = (value:number) => value * Math.PI / 180;
async  getDataByPath(path:string){
  return  get(ref(this.fire.getDatabase(),path)).then(snapshot=>{
     return snapshot.val()
   })
 }
async getNearestStores( cords:{lat:string,lon:string}){
  let stores:string[]=[]
  let storeCords = await this.getDataByPath('search/store/location/cord/')
  for(let id in storeCords){
    if(id!='200'){
      let cord2 = storeCords[id]
      if(cord2?.lat && cord2?.lon){
        let distance = this.getDistanceValue(cords,cord2)
        let discover = cord2.r || 8
        if(distance<=discover){
          stores.push(id)
        }
      }
    }
  }
  if(stores.length!=0)
    sessionStorage.setItem('nearestAllStores',JSON.stringify(stores))
  return stores
 }

  
  }
