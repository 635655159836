import { Component,NgZone } from '@angular/core';
import { SpinnerService } from './main/services/spinner/spinner.service';
import { App, URLOpenListenerEvent,BackButtonListenerEvent } from '@capacitor/app';
import { Location } from '@angular/common';
import { FirebaseService } from './main/services/firebase/firebase.service';
import { StatusBar, Style } from '@capacitor/status-bar';
import { UserdataservieService } from './main/services/firebase/userdata.service';
import { Capacitor } from '@capacitor/core';
import { push, ref } from 'firebase/database';
import { Router } from '@angular/router';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Santhe Bazaar';

  constructor(private router:Router,private zone:NgZone,private spinServ:SpinnerService,private loc:Location,private firebase:FirebaseService,private data:UserdataservieService){}


  spinner=false

  async ngOnInit(){
    this.backButtonFunction();
    this.firebase.getApp()
    this.getSpinner()
    this.checkVersion() 
    this.getSearch()

    this.getDowntime()
    if(Capacitor.getPlatform()!='web'){
        StatusBar.setOverlaysWebView({ overlay: false });
    StatusBar.setBackgroundColor({color:'#ffffff'})
    let a =   await StatusBar.setStyle({ style: Style.Light });   
    }

    this.checkZone()

  } 

 async  checkZone(){
  App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
    this.zone.run(() => {
        const slug = event.url.split(".in").pop();
        if (slug) {
            this.router.navigateByUrl(slug);
        }
    });
});
  }
  async  getDowntime(){
    this.downtime = await  this.data.getDataByPath('/appServices/userMaintainance/') || false
  }
  downtime=false
   


  getSpinner(){
    this.spinServ.showSpinner$.subscribe(res=>{
      this.spinner = res
    })
 }
style=''
 getSearch(){

  this.spinServ.showSearch$.subscribe(res=>{
    if(res)
    this.style = 'height:100%;width:100%;'
    else
    this.style = 'height:1px;width:100%'
  })
}
 CurrentVersion=15

 versionMissmatch=false
 async  checkVersion(){
    let version =  await this.data.getDataByPath('appServices/appVersion') || 0
    if(version>this.CurrentVersion){
     this.versionMissmatch=true
    }
  }

 backButtonFunction(){
  App.addListener('backButton',(event:BackButtonListenerEvent)=>{
    this.spinServ.hideSearch()
    if(this.style == 'height:100%;width:100%;'){
      this.style = 'height:1px;width:100%'
    }else if(event.canGoBack){
        this.loc.back();
    }else{
      this.getExitButton()
    }
  })
}

count=0;
exitPopup=false
getExitButton(){
  this.count++
  if(this.count>1){
    this.exitPopup=true
  }
  setTimeout(()=>{
      this.count=0;
  },3000)
}
exitApp(){
  App.exitApp();
}

ngAfterViewInit() {
  let d = new Date(Date.now())

  if(Capacitor.getPlatform()!='web')
    push(ref(this.firebase.getDatabase(),'analyics/appVisits/'+d.getFullYear()+'/'+d.getMonth()+'/'+d.getDate()),Date.now());
  else
    push(ref(this.firebase.getDatabase(),'analyics/visits/'+d.getFullYear()+'/'+d.getMonth()+'/'+d.getDate()),Date.now());

}

}
