import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginguardGuard } from './main/auth/services/authguard/loginguard.guard';
import { AuthguardGuard } from './main/auth/services/authguard/authguard.guard';
const routes: Routes = [{ path: '', loadChildren: () => import('./main/auth/auth.module').then(m => m.AuthModule) }, 
{ path: 'home', loadChildren: () => import('./main/home/home.module').then(m => m.HomeModule) ,canActivate:[AuthguardGuard]},
 { path: 'admin', loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),canActivate:[LoginguardGuard] },
]
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
