import { Injectable } from '@angular/core';
import { CanActivate, Router, } from '@angular/router';
import { FirebaseService } from 'src/app/main/services/firebase/firebase.service';

@Injectable({
  providedIn: 'root'
})
export class AuthguardGuard implements CanActivate {
  constructor(private fire:FirebaseService,private router:Router){}

  async canActivate( ){
   return this.fire.isAuthenticated().then(async (authenticated)=>{
      if(authenticated){
          return true
      }
      else{
        this.router.navigate(['/login'],{replaceUrl:true})
        return false
      }
    })
      
  }

}
