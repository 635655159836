
<!-- <nav class="navbar d-flex bg-white pe-3 fixed-top"   style="z-index: 3;top:0px;height: 32px;">
  <a href="mail://santhebazaarinfo@gmail.com" class="ms-auto text-dark fs-9 text-decoration-none me-3 "><i class="fi fi-sr-envelope me-2"></i>Email</a>

  <a href="tel://+917795088809" class="text-dark fs-9 text-decoration-none "><i class="fi fi-sr-phone-call me-2"></i>Phone</a>
</nav> -->
<router-outlet></router-outlet>



<div *ngIf="spinner" style="z-index: 5;" class="pop-up pb-3  d-flex justify-content-center bg-white align-items-center">
    <img src="/assets/images/logo/c1bcd8a8c945b53da6b29f10a2a553c0.gif" style="height: 200px;margin-top: 32px;">
</div>
<div  [style]="style" style="z-index: 5;transition: 1s;height:1px;width:100%;overflow: hidden;" class="pop-up shadow bg-white">
  <app-search ></app-search>
</div>

<div *ngIf="versionMissmatch" class="d-flex justify-content-center position-fixed fixed-top h-100 px-5 " style="background-color: rgba(0,0,0,0.25);z-index: 5;">
  <div class="bg-white rounded p-3 my-auto">
    <h5 class="fs-8 fw-bold">New Update Available !!!</h5>
    <h6 class="fs-9">Please update or you might miss latest Offers</h6>
    <div>
      <a class="float-end text-button1" style="text-decoration: none;" href="https://play.google.com/store/apps/details?id=com.splintzeraes.app">Update</a>
      <span class="text-button1" (click)="exitApp()"  >Cancel</span>
    </div>
  </div>
</div>

<div *ngIf="exitPopup" style="z-index: 5;"  class="pop-up z-3 px-5 d-flex justify-content-center">
    <div class="bg-white rounded p-3 my-auto">
      <h5>Do you want to exit this app ?</h5>
      <div>
        <a class="float-end text-button1" style="text-decoration: none;"  (click)="exitApp()">Yes</a>
        <span class="text-button1" (click)="count=0;exitPopup=false">No</span>
      </div>
    </div>
</div>



<div *ngIf="downtime" style="z-index: 5;"  class="pop-up z-3 px-5 d-flex justify-content-center">
  <div class="bg-white rounded p-3 my-auto" >
    <h5 class="p-0 m-0 text-center h6">Sorry For Inconvenience!! </h5>
    <p class="p-0 m-0 text-center  fs-8">We Are Under Maintainance.</p>
    <img class="w-75 mx-auto mt-2  d-block" src="https://static.vecteezy.com/system/resources/thumbnails/018/923/904/small_2x/maintenance-technician-flat-icon-png.png">
    <p class="p-0 text-center m-0  mt-2fs-8">Come After Sometime.</p>
    <div>
      <a class="float-end text-button1 text-primary" style="text-decoration: none;" (click)="exitApp()" >Ok</a>
    </div>
  </div>
</div>
