import { Injectable } from '@angular/core';
import { ref, set, get, child,remove } from 'firebase/database'
import { uploadBytes, ref as sref, getDownloadURL } from 'firebase/storage';
import { FirebaseService } from '../../services/firebase/firebase.service';

@Injectable({
  providedIn: 'root'
})
export class UserdataservieService {
  private audioContext: AudioContext;
  private audioBuffer!: AudioBuffer;

  constructor(private fire:FirebaseService) {
    this.audioContext = new AudioContext();
   }
  storage = this.fire.getStorage()


   
async   getUserdetails(uid:string){

    let userData={
      uid:uid,
     name: (await get(ref(this.fire.getDatabase(),'user/'+uid+'/name'))).val(),
     number: (await get(ref(this.fire.getDatabase(),'user/'+uid+'/number'))).val(),
     location:(await get(ref(this.fire.getDatabase(),'user/'+uid+'/location'))).val(),
   }
  
 return userData
 }


 
  async  getDataByPath(path:string){
     return  get(ref(this.fire.getDatabase(),path)).then(snapshot=>{
        return snapshot.val()
      })
    }

    getcategories(){
      let cat:any[]=[]
      return  get(ref(this.fire.getDatabase(),'categories')).then(all=>{
        all.forEach(cate=>{
          cat.push({imgLink:cate.child('imgLink').val(),name:cate.child('name').val()})

        })
        return  cat
      })
    }



    async getproductDetails(prod:any,prodid:string){
      return  get(ref(this.fire.getDatabase(),'products/'+prod.id)).then(snapshot=>{
        

        prod.varID = prodid 
        prod.name =  snapshot.child('name').val()
        prod.imgLink = snapshot.child('imgLink').val()
        if(prod.varience){
          prod.var = snapshot.child('Variance').child(prod.varience).child('var').val()     
          prod.MRP = snapshot.child('Variance').child(prod.varience).child('MRP').val()  
          prod.price = snapshot.child('Variance').child(prod.varience).child('discPrice').val() 
          prod.outOfStock =  snapshot.child('Variance').child(prod.varience).child('outOfStock').val()  || false
        }else{
          prod.MRP = snapshot.child('MRP').val()
          prod.price = snapshot.child('discPrice').val()
          prod.outOfStock =  snapshot.child('outOfStock').val()  || false
        }

        return prod
      })
    }
  
   async setDataByPath(path:string,data:any){
     set(ref(this.fire.getDatabase(),path),data).then(snapshot=>{
         console.log('data saved success')
       }).catch(()=>{
         console.log('err')
       })
     }
 
 
     deleteDataByPath(path:string){
       remove(ref(this.fire.getDatabase(),path)).then(()=>{
         console.log('deleted success')
       })
     }
 

     async  getImageLink(file:any,id:string){
      let a = await  uploadBytes(sref(this.storage, 'images/'+id+'.png'), file).then((snapshot) => {
           console.log('Uploaded a blob or file!');
           return
         })
       let url = await getDownloadURL(sref(this.storage, 'images/'+id+'.png')).then((url) => {
         return url
       })
       return url
       }
     
async getUniqueOrderID2(){
        return get(ref(this.fire.getDatabase(), 'orders')).then((snapshot) => {
          var generatedCode=''
          for(let i=0;;i++){
             generatedCode = 'O-' + this.generatenumbercode(10)
            if(!snapshot.hasChild(generatedCode))
                break
          }

          return generatedCode
          
        })
      }


async  getUniqueID1(){
    return get(ref(this.fire.getDatabase(), 'products')).then((snapshot) => {
      var generatedCode=''
      for(let i=0;;i++){
         generatedCode = 'P-' + this.generateUniqueCode(6)
        if(!snapshot.hasChild(generatedCode))
            break
      }
      return generatedCode
      
    })
  }

  async  getUniqueRefrrral(){
    return get(ref(this.fire.getDatabase(), 'referral')).then((snapshot) => {
      var generatedCode=''
      for(let i=0;;i++){
         generatedCode = 'R-' + this.generateUniqueCode(4)
        if(!snapshot.hasChild(generatedCode))
            break
      }
      return generatedCode
      
    })
  }




       
  generateUniqueCode(v:number) {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let code = '';
    for (let i = 0; i < v; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      code += characters.charAt(randomIndex);
    }
    return code;
  }

  generatenumbercode(v:number){
    const characters = '0123456789';
    let code = '';
    for (let i = 0; i < v; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      code += characters.charAt(randomIndex);
    }
    return code;
  }



async getProducts(catId:string,search:string){
    return  get(ref(this.fire.getDatabase(),'products')).then(async snapshot=>{
    let prod:any[]=[] 
    snapshot.forEach( prods=>{

      let a=[]
    
      if((prods.child('category').val()==catId || catId =='') &&  (search=='' ||  this.getSearchedProducts(prods.child('desc').val().toLowerCase(),prods.child('name').val().toLowerCase(),search.toLowerCase()) || prods.child('itemCode').val()==search)){  //category filtering
      if(prods.child('Variance').size!=0){                     // variance filtering
        prods.child('Variance').forEach((varience)=>{
          let out = varience.child('outOfStock').val() || false
        prod.push({outOfStock:out,name:prods.child('name').val() + ' ('+varience.child('var').val()+')',category:prods.child('category').val() ,imgLink:varience.child('imgLink').val() || prods.child('imgLink').val(),MRP:varience.child('MRP').val(),price:varience.child('discPrice').val(),id:prods.child('id').val(),varience:varience.key,vrName:varience.child('var').val(),maxCount:prods.child('maxCount').val() || 10})
        })
      }else{
        let out = prods.child('outOfStock').val() || false
        prod.push({outOfStock:out,name:prods.child('name').val(),category:prods.child('category').val() , imgLink:prods.child('imgLink').val(),MRP:prods.child('MRP').val(),price:prods.child('discPrice').val(),id:prods.child('id').val(),varience:'',maxCount:prods.child('maxCount').val() || 10})
      }}
    })

    let outofStock = (await   this.getDataByPath('outOfStock/') || [])

    if(search!='')
    return get(ref(this.fire.getDatabase(),'cafeMenu')).then(snapshot=>{

    snapshot.forEach(el=>{
        if(el.child('name').val().toLowerCase().includes(search.toLowerCase())){
          let out  =  outofStock.includes(el.child('name').val()) || false
          prod.push({outOfStock:out,name:el.child('name').val(), imgLink:el.child('imgLink').val(),MRP:el.child('MRP').val(),price:el.child('discPrice').val(),id:el.child('id').val(),varience:'',maxCount:el.child('maxCount').val() || 10})
        }
    })
      return prod
    })
    else
    return prod
    })
}

getSearchedProducts(desc:string,name:string,search:string){
 let arr =  name.replaceAll(',','').replaceAll('.','').replaceAll('(','').replaceAll(')','').split(' ')
  let exist=false
 for(let i =0;i<arr.length-1;i++){
    for(let j=i+1;j<arr.length;j++){
      // console.log(arr.slice(i).join(' ')==search)
        if(arr.slice(i,j).join(' ')==search || arr.slice(i).join(' ')==search){
        exist=true;break;}
    }

    if(exist)break;
 }
 arr =  desc.split(' ')

 for(let i =0;i<arr.length-1;i++){
  for(let j=i+1;j<arr.length;j++){
    // console.log(arr.slice(i).join(' ')==search)
      if(arr.slice(i,j).join(' ')==search || arr.slice(i).join(' ')==search){
      exist=true;break;}
  }
  if(exist)break;
}

  return exist
}


async getHighSaleProducts(val:number){
  let products:any[] = await this.getProducts('','')
  products=products.filter((a:any)=> {
    if(((a.MRP-a.price)/a.MRP)*100>val)
    {
      return a
    }
   
  })
  products =  products.sort((a:any,b:any)=> (((b.MRP-b.price)/b.MRP)*100) - (((a.MRP-a.price)/a.MRP)*100) )
  
  return products
} 


async getadminsearchProd(search:string){
  return  get(ref(this.fire.getDatabase(),'products')).then(snapshot=>{
    let prod:any[]=[] 
    snapshot.forEach(prods=>{
      if(search=='' || prods.child('name').val().toLowerCase().includes(search.toLowerCase())){  //category filtering
        prod.push(prods.val())
      }
      })
      return prod
    })
}


async loadAudio(url: string): Promise<void> {
  return fetch(url)
    .then((response) => response.arrayBuffer())
    .then((data) => this.audioContext.decodeAudioData(data))
    .then((buffer) => (this.audioBuffer = buffer))
    .then(() => {}); // Resolve with an empty value to satisfy Promise<void>
}
source:any
playAudio(): void {
  this.source = this.audioContext.createBufferSource();
  this.source.buffer = this.audioBuffer;
  this.source.connect(this.audioContext.destination);
  this.source.start();
  
}

stopAudio(){
  this.source.stop()
}





}
